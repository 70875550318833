import React, { useState, useRef, ReactNode, Dispatch, SetStateAction } from 'react';
//Components
import Popup from '../../Components/Atoms/Popup.tsx';

interface IHelpShowMore {
    children: ReactNode
    className?: string;
    isPopupOpen: boolean;
    setPopUpOpen: Dispatch<SetStateAction<boolean>>;
}

function HelpShowMore(props: IHelpShowMore) {
  
    const closePopup = () => {
        props.setPopUpOpen(false);
    };


    return (
        <div className='z-50'>
            {props.isPopupOpen && (
            <Popup isOpen={props.isPopupOpen} closeWindow={closePopup}>
                {props.children}
            </Popup>
            )}
        </div>
    );
}

export default HelpShowMore;