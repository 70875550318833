import React, { ReactNode, useRef } from 'react';
//Assets
import { ReactComponent as CloseModal } from '../../Assets/ModalClose.svg';

interface IPopup {
    children: ReactNode;
    isOpen: boolean;
    closeWindow: () => void;
}

function Popup(props: IPopup) {

    if (!props.isOpen) return null;

    return (
        <div className="flex fixed z-[99] left-0 top-0 w-full h-full overflow-auto bg-brand-dark/40 justify-center items-center m-0 popup ">
          <div className="bg-brand-white w-[80%] lg:w-max p-4 lg:p-8 rounded-2xl relative" onClick={e => e.stopPropagation()}>
            <span className="text-brand-white text-body-desktop-lg font-700 cursor-pointer right-0 top-0 mt-[-8px] mr-[-8px] bg-ocean-blue rounded-2xl p-2 absolute" onClick={props.closeWindow}><CloseModal/></span>
                {props.children}
          </div>
        </div>
      );
}

export default Popup;