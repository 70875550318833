import React from 'react';
//Enums
import {Color} from "../../Enums/Color.ts";
import {Size} from "../../Enums/Size.ts";

enum TextType {
    Hero = "Hero",
    Body = "Body"
}

interface ITextBody {
    children: string;
    size: Size;
    textColor: Color;
    className?: string;
    isThin?: boolean
}

function BodyText(props: ITextBody) {

    let textClasses=''.concat(
        getTextColor()
        , getTextSize()
        , "  whitespace-pre-line "
        ,props.isThin?"font-200 ":"font-500 "
        ,props.className?props.className:''
    );

    function getTextSize() {
        switch (props.size) {
            case Size.Xs:
                return " text-body-desktop-xs"
            case Size.Sm:
                return " text-body-mobile-sm md:text-body-desktop-sm"
            case Size.Md:
                return " text-body-mobile-md md:text-body-desktop-md "
            case Size.Lg:
                return " text-body-desktop-md md:text-body-desktop-lg"
            default:
                return " text-body-desktop-sm"

        }
    }

    function getTextColor() {
        switch (props.textColor) {
            case Color.BrandBlue:
                return " text-brand-blue"
            case Color.Diamond:
                return " text-diamond"
            case Color.DeepBlue:
                return " text-deep-blue"
            case Color.BrandGrey:
                return " text-brand-grey"
            default:
                return " text-brand-blue"
        }
    }

    const parts = props.children.split('<br/>');

    const createMarkup = (htmlString) => {
        // Opcional: Aquí puedes sanitizar el string si hay riesgo de inyección de HTML malicioso
        return { __html: htmlString };
    };

    return (
        <p className={textClasses} dangerouslySetInnerHTML={createMarkup(props.children)} />
    );
}


export {TextType};
export default BodyText;