import React from 'react';
//Enums
import {Size} from "../../Enums/Size.ts";

interface ISubtitle {
    children: string;
    size: Size.Sm | Size.Lg;
    className?: string;  
}

function Subtitle(props: ISubtitle) {

    let textClasses = ''.concat(
        " font-600 whitespace-pre-line text-ocean-blue "
        , getTextSize()
        ,props.className ? props.className : ''
    );

    function getTextSize() {
        switch (props.size) {
            case Size.Sm:
                return " text-subtitle-mobile-sm md:text-subtitle-desktop-sm"
            case Size.Lg:
                return " text-subtitle-mobile-lg md:text-subtitle-desktop-lg"
            default:
                return " text-subtitle-mobile-md md:text-subtitle-desktop-lg"

        }
    }

    return (
        <h2 className={textClasses}>{props.children}</h2>
    );
}

export default Subtitle;