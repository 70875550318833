import React, { ReactNode } from 'react';
//Hooks
import {useIsWindowSizeBellow} from "../../hooks/useIsWindowSizeBellow.ts";
//Enums
import {ScreenSize} from "../../Enums/ScreenSize.ts"

interface IAppStepGuide {
    children: ReactNode;
    iconPath: string;
    numberStep: number;

}

function AppStepGuide(props: IAppStepGuide) {
    return (
        <div className='flex flex-row space-x-2 md:space-x-4 justify-start items-center'>
            {useIsWindowSizeBellow(ScreenSize.Tablet)?<></>:<span className='font-800 text-title-lg  lg:text-title-desktop-lg text-white '>{props.numberStep}-</span>}
            <img src={props.iconPath} alt="App IOS first step icon" height={useIsWindowSizeBellow(ScreenSize.Tablet)?20:36} width={useIsWindowSizeBellow(ScreenSize.Tablet)?20:36}/>
            <div className=' flex items-center justify-start text-start'>{props.children}</div>
        </div>
    );
}

export default AppStepGuide;