import React, { ReactNode } from 'react';

interface IAppStepGuideContainer {
    children: ReactNode;
}

function AppStepGuideContainer(props: IAppStepGuideContainer) {

    return (
        <div className='flex flex-col space-y-5 bg-deep-marine-blue lg:bg-transparent  rounded-xl py-[12px] px-[8px]'>
            {props.children}
        </div>
    );
}

export default AppStepGuideContainer;