import React, { ReactNode } from 'react';
import Slider from "react-slick";
//Assets
import { ReactComponent as PrevArrowVector } from '../../Assets/CarrouselPrevArrow.svg';
import { ReactComponent as NextArrowVector } from '../../Assets/CarrouselNextArrow.svg';

const NextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <NextArrowVector className='ml-[-50px]'/>
    </div>
  );
};
  
const PrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className+" z-40"}
      onClick={onClick}
    >
      <PrevArrowVector className='mr-[-50px]'/>
    </div>
  );
};

interface ISliderImageCard {
    children: ReactNode;
}

function SliderImageCard(props: ISliderImageCard) {

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow  className={"z-40"}/>
      };

    return (
        <Slider {...settings} >
            {props.children}
        </Slider>
    );
}

export default SliderImageCard;