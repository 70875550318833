import React, { ReactNode, useRef } from 'react';
//Assets

interface IWhatsAppIcon {
    phoneNumber: String;
}

function WhatsAppIcon(props: IWhatsAppIcon) {
    const handleWhatsAppClick = () => {
        const whatsappURL = `https://wa.me/${props.phoneNumber}`;
        window.open(whatsappURL, "_blank");
      };

      return (
        <div className="relative z-50">
          <div className="fixed bottom-5 right-5 flex items-center justify-center w-[70px] h-[70px]">
            <div className="absolute w-full h-full bg-green-300 opacity-50 rounded-full animate-ripple"></div>
            <div className="absolute w-full h-full bg-green-300 opacity-50 rounded-full animate-ripple delay-200"></div>
            <div className="absolute w-full h-full bg-green-300 opacity-50 rounded-full animate-ripple delay-400"></div>
            <button 
              onClick={handleWhatsAppClick} 
              className="relative bg-transparent border-none cursor-pointer z-10 animate-pulse">
              <img src="./images/wp-icon.png" alt="WhatsApp" className="w-12 h-12" />
            </button>
          </div>
        </div>
      );
}

export default WhatsAppIcon;