import { useEffect, useRef, useState, RefObject } from 'react';

interface UseIntersectionObserverOptions {
    threshold: number;
    root?: Element | null;
    rootMargin?: string;
}

const useIntersectionObserver = (
    options: UseIntersectionObserverOptions
): [RefObject<HTMLDivElement>, boolean] => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const currentElement = ref.current;
        const observer = new IntersectionObserver(
            (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.disconnect();
                    }
                });
            },
            options
        );

        if (currentElement) {
            observer.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                observer.disconnect();
            }
        };
    }, [options]);

    return [ref, isVisible];
};

export default useIntersectionObserver;