import React, { ReactNode } from 'react';

interface IFooter {
    children: ReactNode;
}

function Footer(props: IFooter) {

    return (
        <div className="flex flex-col-reverse md:flex-row justify-between text-start mt-auto bg-diamond px-4 md:px-8 xl:px-20 pb-4 md:pb-8">
            {props.children}
        </div>
    );
}

export default Footer;