import React, { useState, useRef, ReactNode } from 'react';
//Components
import Button, {ButtonType} from '../../Components/Atoms/Button.tsx';
import Popup from '../../Components/Atoms/Popup.tsx';
//Hooks
import {useIsWindowSizeBellow} from "../../hooks/useIsWindowSizeBellow.ts";
//Enums
import {ScreenSize} from "../../Enums/ScreenSize.ts"
//Assets
import { ReactComponent as AndroidIcon } from '../../Assets/AppSection/AndroidIcon.svg';
import { ReactComponent as IOSIcon } from '../../Assets/AppSection/IOSIcon.svg';

enum DeviceOption {
    Android= "Android",
    IOS = "IOS",
}

interface IAppDeviceOption {
    children: ReactNode
    className?: string;
    deviceOption: DeviceOption;
    videoPath: string;
}

function AppDeviceOption(props: IAppDeviceOption) {

    const [isOpen, setIsOpen] = useState(false);
    const videoRef = useRef(null);

    let iconHeights = useIsWindowSizeBellow(ScreenSize.Tablet)?50:80;
    let iconWidths = useIsWindowSizeBellow(ScreenSize.Tablet)?40:70;

    const openPopup = () => {
      setIsOpen(true);
      if (videoRef.current) {
        videoRef.current.play();
      }
    };
  
    const closePopup = () => {
      setIsOpen(false);
      if (videoRef.current) {
        videoRef.current.pause();
      }
    };

    let AppDeviceOptionClasses = ''.concat(
        " flex flex-col text-center max-w-[6.125rem] md:max-w-[12.938rem] justify-center items-center space-y-8 "
        ,props.className ? props.className : ''
    );

    return (
        <div className={AppDeviceOptionClasses}>
            <div className=''>
                {props.deviceOption === DeviceOption.Android?
                <AndroidIcon height={iconHeights} width={iconWidths}/>:
                <IOSIcon height={iconHeights} width={iconWidths}/>}
            </div>
            {props.children}
            <Button type={ButtonType.Secondary} isFullWidth={true} className='mt-2 md:mt-4 font-700'  onClickFunction={openPopup}>
                {props.deviceOption === DeviceOption.Android?"Android":"IOS"}
            </Button>
            {isOpen && (
            <Popup isOpen={isOpen} closeWindow={closePopup}>
                <video ref={videoRef} width="320" height="240" controls className='mx-auto rounded-2xl'>
                    <source src={props.videoPath} type="video/mp4" />
                    Tu navegador no soporta videos HTML5.
                </video>
            </Popup>
            )}
        </div>
    );
}

export {DeviceOption};
export default AppDeviceOption;