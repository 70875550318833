import React, {ReactNode, useState} from 'react';
import  { NavLink } from 'react-router-dom';
//Helpers
import {modifiedLinks} from '../../Helpers/GlobalHelper.tsx';
import {useIsWindowSizeBellow} from '../../hooks/useIsWindowSizeBellow.ts';
//Components
import Button, {ButtonType} from '../../Components/Atoms/Button.tsx';
//Assets
import { ReactComponent as Login } from '../../Assets/loginIconMenu.svg';
import { ReactComponent as CloseMenu } from '../../Assets/CloseMenu.svg';
import { ReactComponent as OpenMenu } from '../../Assets/OpenMenu.svg';
//Enums
import {ScreenSize} from "../../Enums/ScreenSize.ts";

interface INavBar {
    logoPath : string;
    children: ReactNode;
}

function NavBar(props: INavBar) {

    const isWindowSizeBelow = useIsWindowSizeBellow(ScreenSize.Tablet);

    const [mobileMenu, setMobileMenu] = useState(false);

    const ShowMobileMenu = () => {
        setMobileMenu(!mobileMenu);
    }

    const closeMenuOnMobile = ()=>{
        setMobileMenu(false);
    }

    return (
        <header className='w-full z-50 top-0'>
            <nav className='w-full px-5 lg:px-32 py-2 lg:py-7 flex flex-row justify-between items-center bg-gray/900/50 relative'>
                <div className='text-brand/white mr-auto'> <NavLink to='/' className={'mr-auto '}><img src={props.logoPath} alt="" className=' mr-auto '/></NavLink></div>
                <div className='text-brand/white flex lg:hidden ml-auto items-center space-x-6' onClick={ShowMobileMenu}>
                    {/*<Button type={ButtonType.Primary} path='#?'>
                        <Login className='mx-[8px]'/>
                    </Button>*/}
                    {
                        mobileMenu ?
                        <CloseMenu fill='#003282' stroke='#003282' height='32px' width='32px' className='ml-auto'/>
                        :<OpenMenu fill='#003282' stroke='#003282' height='32px' width='32px' className='ml-auto'/>
                    }
                </div>
                <ul className='hidden lg:flex flex-row items-center space-x-12'>{props.children}</ul>
            </nav>
            {mobileMenu && isWindowSizeBelow?<ul className='flex flex-col p-5 space-y-5 bg-brand-white w-full h-full absolute z-50'>{modifiedLinks(closeMenuOnMobile,props.children)}</ul>:''}
        </header>

    );
}

export default NavBar;