import React, { ReactNode } from 'react';
//Enums
import {HorizontalOrientation} from "../../Enums/HorizontalOrientation.ts";

interface IInfoCard{
    children: ReactNode;
    className?: string;
    textOrientation: HorizontalOrientation;
}

function InfoCard(props: IInfoCard) {

    const getTextAlign=()=>{
        switch (props.textOrientation) {
            case HorizontalOrientation.Start:
                 return " text-start justify-start items-start  "
                break;
            case HorizontalOrientation.Center:
                 return " text-center justify-center items-center "
                break;
            case HorizontalOrientation.End:
                return " text-end justify-end items-end  "
                break;
            default:
                return" text-start justify-start items-start  "
                break;
        }
    }

    let InfoCardClasses = ''.concat(
        " flex flex-col bg-diamond text-deep-blue rounded-2xl p-4 md:px-14 md:pt-14 md:pb-7"
        ,getTextAlign()
        ,props.className ? props.className : ''
    );

    return (
        <div className={InfoCardClasses}>
            {props.children}
        </div>
    );
}

export default InfoCard;