import React, { ReactNode } from 'react';
//Hooks
import {useIsWindowSizeBellow} from "../../hooks/useIsWindowSizeBellow.ts";
//Enums
import {HorizontalOrientation} from "../../Enums/HorizontalOrientation.ts";
import {ScreenSize} from "../../Enums/ScreenSize.ts";

interface IInfoImageCard {
    children: ReactNode;
    className?: string;
    imagePath: string;
    textOrientation: HorizontalOrientation;
}

function InfoImageCard(props: IInfoImageCard) {

    const childArray = React.Children.toArray(props.children);

    const getTextAlign=()=>{
        switch (props.textOrientation) {
            case HorizontalOrientation.Start:
                 return " text-center justify-center items-center md:text-start md:justify-start md:items-start bg-gradient-to-r from-soft-blue from-57% to-grey-info-card to-57%"
                break;
            case HorizontalOrientation.End:
                return " text-center justify-center items-center  md:md:text-end md:justify-end md:items-start  bg-gradient-to-l from-soft-blue from-57% to-grey-info-card to-57%"
                break;
            default:
                return" text-center justify-center items-center  md:text-start md:justify-start md:items-start bg-gradient-to-r from-soft-blue from-57% to-grey-info-card to-57%"
                break;
        }
    }

    let InfoImageCardClasses = ''.concat(
        " flex flex-col lg:flex-row  relative min-h-[19rem]  text-diamond rounded-2xl p-8"
        ,getTextAlign()
        ,props.className ? props.className : ''
    );

    let textClasses = ''.concat(
        " md:w-[50%] h-full space-y-2 md:space-y-4"
        ,props.textOrientation === HorizontalOrientation.Start?" mr-auto ":" ml-auto "
    );

    let imageClasses = ''.concat(
        " md:absolute right-0 left-0 bottom-0 top-0 mt-auto md:w-[50%] xl:w-auto"
        ,props.textOrientation === HorizontalOrientation.Start?" ml-auto ":" mr-auto "
    );

    return (
        <div className={InfoImageCardClasses}>
            {useIsWindowSizeBellow(ScreenSize.Mobile)?<></>:<img src={props.imagePath} alt="" className={imageClasses}/>}
            <div className={textClasses}>
                {childArray[0]}
                {useIsWindowSizeBellow(ScreenSize.Mobile)?<img src={props.imagePath} alt="" className={imageClasses}/>:<></>}
                {childArray[1]}
            </div>
        </div>
    );
}

export default InfoImageCard;