import React from 'react'
//Assets
import { ReactComponent as SocialFacebookIcon } from '../../Assets/Social/facebook-social-icon.svg';
import { ReactComponent as SocialInstagramIcon } from '../../Assets/Social/instagram-social-icon.svg';
import { ReactComponent as SocialLinkedinIcon } from '../../Assets/Social/linkedin-social-icon.svg';

enum IconType {
    SocialFacebook= "SocialFacebook",
    SocialInstagram = "SocialInstagram",
    SocialLikedin = "SocialLikedin",
}

interface ISocialIcons {
    color: string;
    path?: string;
    type: IconType;
    isExternal?: boolean;

}

function SocialIcons(props: ISocialIcons) {

    const getIcon=()=>{
        switch (props.type) {
            case IconType.SocialFacebook:
                 return(<SocialFacebookIcon fill={props.color} stroke={props.color} height="24px" width="24px"/>)
            break;
            case IconType.SocialInstagram:
                return(<SocialInstagramIcon fill={props.color} stroke={props.color} height="24px" width="24px"/>)
            break;
            case IconType.SocialLikedin:
                return(<SocialLinkedinIcon fill={props.color} stroke={props.color} height="24px" width="24px"/>)
            break;
            default:
                return(<SocialFacebookIcon fill={props.color} stroke={props.color} height="24px" width="24px"/>)
            break;
        }
    }

    return (
    <a href={props.path} target={props.isExternal?'blank_':''} className="flex justify-items-center cursor-pointer">
        {getIcon()} 
    </a>         
  );
}

export {IconType};
export default SocialIcons;