import React, { ReactNode } from 'react';

interface IFooterItem {
    children: ReactNode;
}

function FooterItem(props: IFooterItem) {

    return (
        <div className="flex flex-col space-y-1 md:space-y-2 text-center text-start md:max-w-[12.5rem]  lg:max-w-full">
            {props.children}
        </div>
    );
}

export default FooterItem;