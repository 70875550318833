import React, { ReactNode } from 'react';

interface IDoubleSection {
    children:  [ReactNode,ReactNode];
    isReverse?: boolean;
    className?: string;
}

function DoubleSection(props: IDoubleSection) {

    let doubleSectionClasses =''.concat(
        "flex flex-row justify-between w-full mt-[27rem] md:mt-[38.375rem]"
       , props.className?props.className:''
       , props.isReverse?'flex-col-reverse':''
   );

    const eachSectionClasses ='flex flex-col space-y-8 w-[46%]';
    
    let leftSection =''.concat(
        eachSectionClasses,
        ' items-end lg:items-start '
   );
    let rightSection =''.concat(
        eachSectionClasses,
        ' justify-start items-start lg:items-end lg:justify-end '
   );



  
    return (
        <div className={doubleSectionClasses}>
            <div className={leftSection}>
                {props.children && typeof props.children === 'object'?props.children[0]:''}
            </div>
            <div className={rightSection}>
                {props.children && typeof props.children === 'object'?props.children[1]:''}
            </div>
        </div>
    );
    }

export default DoubleSection;