import React, {ReactNode} from 'react';

//Modifica los links
export const modifiedLinks = (newFn: () => void, children: ReactNode) => {

    const childrenArray = Array.isArray(children) ? children : [children];

    const child = [] as any[];

    childrenArray.map((ch) => {
            const newChildren = Object.assign({}, ch, {
                props: Object.assign({}, ch.props, {
                    onClickFunction: newFn
                })
              });
            return child.push(newChildren);  
    });
    return child;
}