import React, { ReactNode } from 'react';
//Enums
import {Color} from "../../Enums/Color.ts";
import {Size} from "../../Enums/Size.ts";

enum TitleType {
    Standard = "Standard",
    InfoCardTitle = "InfoCardTitle"
}

interface ITitle {
    children: ReactNode;
    size: Size;
    titleType : TitleType;
    textColor: Color;
    className?: string;
}

function Title(props: ITitle) {

    let titleClasses = ''.concat(
        " font-700 whitespace-pre-line "
        , getTextColor()
        , getTextSize()
        , props.titleType === TitleType.Standard?"":" text-shadow-standard "
        , props.className?props.className:''
    );

    function getTextSize() {
        switch (props.size) {
            case Size.Xs:
                return " text-title-xs md:text-title-desktop-xs"
            case Size.Sm:
                return " text-title-sm md:text-title-desktop-sm"
            case Size.Md:
                return " text-title-md md:text-title-desktop-md"
            case Size.Lg:
                return " text-title-lg md:text-title-desktop-lg"
            default:
                return " text-title-sm md:text-title-desktop-sm"

        }
    }

    function getTextColor() {
        switch (props.textColor) {
            case Color.BrandBlue:
                return " text-brand-blue"
            case Color.Diamond:
                return " text-diamond"
            case Color.DeepBlue:
                return " text-deep-blue"
            case Color.SoftBlue:
                return " text-soft-blue"
            case Color.BrandWhite:
                return " text-brand-white"
            default:
                return " text-brand-blue"
        }
    }

    return (
        <h1 className={titleClasses}>{props.children}</h1>  
    );
    }

export {TitleType};
export default Title;