import React, { ReactNode } from 'react';

interface IFooterIremContainer {
    children: ReactNode;
}

function FooterIremContainer(props: IFooterIremContainer) {

    return (
        <div className="flex flex-col md:flex-row bg-[#C9D8EE] md:bg-transparent rounded-2xl p-4 space-y-1 md:space-y-0  md:space-x-4 lg:space-x-16 xl:space-x-56">
            {props.children}
        </div>
    );
}

export default FooterIremContainer;