import React, {ReactNode} from 'react'

enum ButtonType {
    Primary = "Primary",
    Secondary = "Secondary",
    Third = "Third"
}

interface IButton {

    type: ButtonType;
    path?: string;
    onClickFunction?: () => void;
    isExternal?: boolean;
    className?: string;
    children: ReactNode;
    isFullWidth?: boolean;
}

function Button(props: IButton) {
    let buttonsWrapperClasses =''.concat(
        " flex justify-center align-middle "
       , props.className?props.className:''
       ,props.isFullWidth?" w-full ":" min-w-[4rem] "
   );


    let buttonClasses = ''.concat(
        " p-4 rounded-3xl flex justify-center align-middle"
        ,getButtonType()
        ,props.isFullWidth?" w-full ":" min-w-[4rem] "
    );

    function getButtonType() {
        switch (props.type) {
            case ButtonType.Primary:
                return " bg-marine-blue text-brand-white shadow-primary-button"
            case ButtonType.Secondary:
                return " bg-brand-white  text-ocean-blue border-ocean-blue border-2 shadow-secondary-button"
            case ButtonType.Third:
                return " bg-brand-white  text-ocean-blue shadow-secondary-button p-1.5 min-w-[3.5rem]"
            default:
                return " "
        }
    }

    return (
        <div className={buttonsWrapperClasses}>
            <button className={buttonClasses + " cursor-pointer"} onClick={props.onClickFunction}>
                <a href={props.path} target={props.isExternal?'blank_':''} className='text-button-mobile md:text-button-desktop flex flex-row justify-center items-center'>{props.children}</a>
            </button>
        </div>
    );
}

export {ButtonType};
export default Button;