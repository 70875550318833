import React, { ReactNode } from 'react';

interface IIconsContainer {
    children: ReactNode;
}

function IconsContainer(props: IIconsContainer) {

    return (
        <div className="flex flex-row space-x-8 justify-center md:ml-4  lg:ml-auto md:justify-start mb-6 md:mt-0">
            {props.children}
        </div>
    );
}

export default IconsContainer;