import React, { ReactNode } from 'react';

enum Position {
    Top = 'Top', 
    Center = 'Center',
    Bottom = 'Bottom'
}

interface ISection {
    className?: string;
    childrenClassName?: string;
    imagePath?  : string;
    withPaddings: boolean;
    children : ReactNode;
    height : number;
    align : Position;
    isContentMax? : boolean;
    sectionId?: string;
}

function Section(props: ISection) {

    let wrapperSectionClassname = ''.concat(
        props.withPaddings?' px-7 md:px-8 xl:px-[96px]':''
        , " flex flex-col bg-no-repeat relative w-full overflow-x-hidden "
        , props.className?props.className:''
    );

    let childrenClassname = ''.concat(
        props.childrenClassName?props.childrenClassName:''
        ,getChildrenPosition()
        , " z-40 w-full bg-transparent"
    );

    function getChildrenPosition() {
        switch (props.align) {
            case Position.Top:
                return " top-0"
            case Position.Center:
                return " top-0 bottom-0 my-auto"
            case Position.Bottom:
                return " bottom-0 mt-auto"
            default:
                return " top-0"
        }
    }

    let sectionHeight = props.isContentMax?'max-content':props.height+'rem'
  
    return (
        <div id={props.sectionId} className={wrapperSectionClassname} style={{'backgroundImage':'url('+props.imagePath+')','height':sectionHeight}}>
            <div className={childrenClassname}>
                {props.children}
            </div>
        </div>
    );
    }

export {Position};
export default Section;