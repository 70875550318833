import React from 'react'
//Enums
import {Color} from "../../Enums/Color.ts";

enum LinkType {
    Navigation = "Navigation",
    Home = "Home",
    Footer = "Footer"
}

interface IGeneralLink {
    children: string;
    type: LinkType;
    isExternal?: boolean;
    textColor: Color.SoftBlue | Color.BrandGrey | Color.OceanBlue  | Color.DeepBlue;
    isDecorated: boolean;
    path: string;
    className?: string;
    onClickFunction?: () => void;
}

function GeneralLink(props: IGeneralLink) {
    let GeneralLinkClasses=''.concat(
        " font-600 cursor-pointer break-words "
        ,getTextColor()
        ,props.isDecorated? ' underline decoration-1 ': ''
        ,getLinkType()
        ,props.className?props.className:''
    );

    function getTextColor() {
        switch (props.textColor) {
            case Color.SoftBlue:
                return " text-soft-blue"
            case Color.BrandGrey:
                return " text-brand-grey"
            case Color.OceanBlue:
                return " text-ocean-blue"
            case Color.DeepBlue:
                return " text-deep-blue"
            default:
                return " text-ocean-blue"
        }
    }

    function getLinkType() {
        switch (props.type) {
            case LinkType.Navigation:
                return " text-navigation-link "
            case LinkType.Home:
                return " text-home-link "
            case LinkType.Footer:
                return " text-footer-link font-100 md:font-300 "
            default:
                return " text-navigation-link "
        }
    }

    return (
        <a href={props.path} target={props.isExternal?'blank_':''} className={GeneralLinkClasses} onClick={props.onClickFunction}>{props.children}</a>
    );
    }

export {LinkType};
export default GeneralLink;