import React, { ReactNode } from 'react';

interface IHelpItem {
    children: ReactNode;
}

function HelpItemContainer(props: IHelpItem) {

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {props.children}
        </div>
    );
}

export default HelpItemContainer;