import React, { ReactNode } from 'react';
//Enums
import {HorizontalOrientation} from "../../Enums/HorizontalOrientation.ts";

interface ITextArea {
    children: ReactNode;
    className?: string;
    textAlign?: HorizontalOrientation;
}

function TextArea(props: ITextArea) {

    const getTextAlign=()=>{
        switch (props.textAlign) {
            case HorizontalOrientation.Start:
                 return " text-start justify-start items-start  "
                break;
            case HorizontalOrientation.Center:
                 return " text-center justify-center items-center "
                break;
            case HorizontalOrientation.End:
                return " text-end justify-end items-end  "
                break;
            default:
                return" text-start justify-start items-start  "
                break;
        }
    }

    let textAreaClasses = ''.concat(
        " flex flex-col space-y-2 md:space-y-4 "
        ,props.textAlign ? getTextAlign() : ''
        ,props.className ? props.className : ''
    );

    return (
        <div className={textAreaClasses}>
            {props.children}
        </div>
    );
}

export default TextArea;